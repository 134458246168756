import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  body{
    margin:0;
    padding:0;
    background-color:#f7f1e3;
    /* background-color: #ff4757; */
    font-family: 'Fredoka', sans-serif;
  }
`;
